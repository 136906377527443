<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" min-height="560">
            <v-toolbar flat>
              <v-toolbar-title>
              <h1 class="headline">
                  <v-icon color="primary" class="mb-1 mr-2">mdi-domain</v-icon>
                  {{ $tc('DOMAIN', 2) }}</h1>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon color="grey" class="mb-1 mr-2">mdi-dots-vertical</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Obtén un dominio para tu sitio web</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-card min-height="120">
                        <v-card-text>
                          <v-row justify="space-between">
                            <v-col cols="12" sm="4" class="text-center">
                              <v-icon size="64" color="primary">mdi-cart-arrow-down</v-icon>
                            </v-col>
                            <v-col cols="12" sm="8" class="text-left">
                              <h1 class="mt-4 headline">Comprar un dominio</h1> 
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-card min-height="120">
                        <v-card-text>
                          <v-row justify="space-between">
                            <v-col cols="12" sm="4" class="text-center">
                              <v-icon size="64" color="primary">mdi-link-variant-plus</v-icon>
                            </v-col>
                            <v-col cols="12" sm="8" class="text-left">
                              <h1 class="mt-4 headline">
                      Conectar un dominio existente</h1> 
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="title font-weight-regular">¿Qué es un dominio?</span>
                  <p>Un dominio es tu propia dirección web única (como arigato.ai)</p>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="title font-weight-regular">¿Por qué la necesito?</span>
                  <p>Al conectar tu sitio a un dominio, logras verte profesional, y promocionas y fortaleces tu marca. Al mismo tiempo es más fácil para los visitantes encontrar tu sitio en los motores de búsqueda como Google.</p>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="title font-weight-regular">Obtén una casilla de correo</span>
                  <p>Obtén una casilla de correo
Completa tu presencia online y potencia tu marca con un correo personalizado para tu dominio.</p>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pa-0">
            </v-card-actions>
          </v-card>
        </v-col>
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "domains",
  data() {
    return {
      
    }
  },
}
</script>

<style>

</style>
